import PropTypes from "prop-types"
import React from "react"

import "./HeroVariantOne.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner/section-inner"
import IconLink from "../../components/icon-link"
import SectionOuter from "../../components/section-outer"
import ArrowRight from "../../assets/svg/icons/arrow-right.svg"
import GatsbyImage from "gatsby-image"
import classNames from "classnames"

const HeroVariantOne = ({ title, isMobileTop, icon, description, links, backgroundImage }) => {


  const innerElement = <SectionInner className={classNames("HeroVariantOne-inner", {isMobileTop}) }>
    <div className="HeroVariantOne-info u-responsiveFlexAlignWithMax u-responsiveTextAlignWithMax">
      <div className="HeroVariantOne-titleWrapper u-flex u-responsiveFlexJustifyWithMax">
        <h2 className="HeroVariantOne-title t-h1">
          {title}
        </h2>
        {
          icon &&
          <img className="HeroVariantOne-icon" src={icon.imageFile ? icon.imageFile.publicURL : icon.sourceUrl}
               alt={icon.altText}/>
        }

      </div>
      <div className="HeroVariantOne-description t-sectionBodyBig u-indent-left-small"
           dangerouslySetInnerHTML={{ __html: description }}>

      </div>
      {/*<div className={""}>*/}
        {
          links &&
          links.map(({ link }, index) => {
            if (!link) return null

            return <IconLink key={index}
                             className="u-indent-left-small u-responsiveFlexJustifyWithMax"
                             to={link.relativeUrl} title={link.title}
                             target={link.target} icon={ArrowRight}>
              {link.title}
            </IconLink>
          })
        }
      {/*</div>*/}
    </div>
  </SectionInner>

  const outerElement = <SectionOuter className="HeroVariantOne-bgImage">
    {
      backgroundImage &&
      (
        (backgroundImage.imageFile && backgroundImage.imageFile.extension !== "svg") ?
          <GatsbyImage className={"HeroVariantOne-image"} fluid={backgroundImage.imageFile.childImageSharp.fluid} alt={backgroundImage.altText}/>
          :
          <img className="HeroVariantOne-image"
               src={backgroundImage.imageFile ? backgroundImage.imageFile.publicURL : backgroundImage.sourceUrl}
               alt={backgroundImage.altText}/>
      )
    }
  </SectionOuter>

  return (
    <Section className="HeroVariantOne isMobileTop">

      {
        !isMobileTop ? innerElement : outerElement
      }

      {
        isMobileTop ? innerElement : outerElement
      }


    </Section>
  )
}

HeroVariantOne.defaultProps = {
  isMobileTop: false,
}

HeroVariantOne.propTypes = {
  title: PropTypes.string,
  iconUrl: PropTypes.string,
  description: PropTypes.string,
  readMoreText: PropTypes.string,
  readMoreUrl: PropTypes.string,
  isMobileTop: PropTypes.bool,
}

export default HeroVariantOne
