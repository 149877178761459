import Link from "../link"
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "./icon-link.scss"

const IconLink = ({ to, title, children, icon, className, before, absolute }) => {
  const Icon = icon
  return (
    <Link className={classNames("IconLink t-iconLink", className, {before: before})} to={to} title={title} absolute={absolute}>
      {!before ? children : <Icon/>}
      {before ? children : <Icon/>}
    </Link>
  )
}

IconLink.propTypes = {
  to: PropTypes.string,
}

export default IconLink
