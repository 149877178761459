export const createMailTo = ({ title, t, lang }) => {
  const mailBodyDe = "Sehr geehrtes Support-Team,\n" +
    "\n" +
    "bitte senden Sie mir ein Angebot für das Trnsys18 Add-On " + title + " zu (bitte zutreffendes auswählen).\n" +
    "\n" +
    "O TRNFlow (Hochschullizenz):    1.900 € zzgl. gesetzliche USt.\n" +
    "\n" +
    "O TRNFlow (komerzielle Lizenz 1-user): 2.500 € zzgl. gesetzliche USt.\n" +
    "\n" +
    "O TRNFlow (komerzielle Lizenz 5-user): 4.063 € zzgl. gesetzliche USt.\n" +
    "\n" +
    "Adresse (bitte ausfüllen) \n" +
    "TRNSYS User-ID: \n" +
    "Vorname und Name: \n" +
    "Firma/Hochschule/Institut: \n" +
    "Straße und Hausnummer: \n" +
    "PLZ, Stadt: \n" +
    "Land: \n" +
    "E-Mail:\n" +
    "ggf. Tel.:"

  const mailBodyEn = "Dear Support Team,\n" +
    "\n" +
    "please send me an offer for the Trnsys18 Add-On " + title + " (please select the appropriate one).\n" +
    "\n" +
    "O TRNFlow (university license): 1.900 € plus statutory VAT\n" +
    "\n" +
    "O TRNFlow (commercial license 1-user): 2.500 € plus VAT\n" +
    "\n" +
    "O TRNFlow (commercial license 5-user): 4.063 € plus statutory VAT\n" +
    "\n" +
    "Address (please fill in)\n" +
    "TRNSYS User ID:\n" +
    "First and last name:\n" +
    "Company/University/Institute:\n" +
    "Street and house number:\n" +
    "Postcode, City:\n" +
    "country:\n" +
    "e-mail:\n" +
    "if necessary Tel:"

  return "mailto:hotline@transsolar.com?subject=" + t("Angebot") + ": " + encodeURIComponent("Trnsys18 Add-On " + title) + "&body=" + encodeURIComponent(lang === "de_DE" ? mailBodyDe : mailBodyEn)
}
