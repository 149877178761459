import classNames from "classnames"
import GatsbyImage from "gatsby-image"
import React from "react"
import CardMedia from "@material-ui/core/CardMedia"

export const getImage = (featuredImage, isRound, classes, wrapperClasses, colored) => {
  if (featuredImage) {

    if (!featuredImage.imageFile || (!featuredImage.imageFile.childImageSharp && featuredImage.imageFile.extension === "svg")) {
      return <div className={classNames("gatsby-image-wrapper", wrapperClasses)}>
        <div className={classNames("u-aspect-base ratio-1-1", { "u-round-image": isRound }, classes)}>
          <img className={classNames("u-aspect-content", { "u-round-image": isRound, "c-accent-dark": colored })}
               src={featuredImage.imageFile ? featuredImage.imageFile.publicURL : featuredImage.sourceUrl }
               alt={featuredImage.altText}/>
        </div>
      </div>
    }

    return <GatsbyImage className={classNames({ "u-round-image": isRound}, classes)}
                        fluid={featuredImage.imageFile.childImageSharp.fluid}
                        alt={featuredImage.altText ? featuredImage.altText : ""}/>
  }

  return null
}


export const getCardMedia = (featuredImage) => {
  if (featuredImage) {

    if (!featuredImage.imageFile || (!featuredImage.imageFile.childImageSharp && featuredImage.imageFile.extension === "svg")) {
      return <div className="u-aspect-base ratio-16-9">
        <CardMedia
          className="u-aspect-content"
          component="img"
          src={featuredImage.imageFile ? featuredImage.imageFile.publicURL : featuredImage.sourceUrl}
          alt={featuredImage.altText}
          title={featuredImage.altText}
        />
      </div>
    }

    return <CardMedia
      component={GatsbyImage}
      fluid={featuredImage.imageFile.childImageSharp.fluid}
      alt={featuredImage.altText ? featuredImage.altText : ""}
    />
  }

  return <div className="u-aspect-base ratio-16-9"/>
}
