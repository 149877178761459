import React from "react"
import CardActions from "@material-ui/core/CardActions"
import IconLink from "../icon-link"
import ArrowRight from "../../assets/svg/icons/arrow-right.svg"
import PdfIcon from "../../assets/svg/icons/pdf.svg"
import { GetApp, Mail as MailIcon } from "@material-ui/icons"
import Button from "@material-ui/core/Button"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { setShowOrderFab, updateAddonCount } from "../../state/actions"
import classNames from "classnames"
import { createMailTo } from "../comp-utils"

const CardAction = ({ index, lang, type, isArticle, title, price, articlePath, downloadLink, showOnlyPdf, pdf, t, isTraining, isAddonGrid, updateAddonCount, count, setShowOrderFab }) => {
  price = price ? price : 0
  const hasPrice = price && price !== 0

  if (isArticle) {
    return (
      <CardActions className="ProductCard-actions">
        <IconLink to={articlePath} title={title} className="ProductCard-actionButton" icon={ArrowRight}>
          {
            t("Weiterlesen")
          }
        </IconLink>
      </CardActions>
    )
  } else if (showOnlyPdf) {
    return pdf ? (
      <CardActions className="ProductCard-actions">
        <IconLink absolute to={pdf.imageFile ? pdf.imageFile.publicURL : pdf.mediaItemUrl} title={pdf.title}
                  className="ProductCard-actionButton" icon={PdfIcon}>
          {
            t("Mehr Erfahren")
          }
        </IconLink>
      </CardActions>
    ) : null
  }

  const handleAddonAction = (e) => {
    e.preventDefault()
    if (isAddonGrid) {
      updateAddonCount(index, count === 0 ? 1 : 0)

      setShowOrderFab(1)
    }
  }

  const actionButton = () => {
    if (isTraining) {

      return <Button
        href={"mailto:hotline@transsolar.com?" + t("mailBodyTraining")}
        title={"Anfrage"} target="_blank" size="small" color={"secondary"}
        className={"ProductCard-actionButton"}>
        {t("ANFRAGE")}
        <MailIcon className={"u-leftMarginSm"}/>
      </Button>
    } else if (type === "Contact Sales") {

      return <Button
        href={createMailTo({title, t, lang})}
        title={"Anfrage"} target="_blank" size="small" color={"secondary"}
        className={"ProductCard-actionButton"}>
        {t("ANFRAGE")}
        <MailIcon className={"u-leftMarginSm"}/>
      </Button>
    }


    return hasPrice || !downloadLink ?
      <Button onClick={handleAddonAction} title={"Download"} target="_blank" size="small" color={"secondary"}
              className={classNames("ProductCard-actionButton", { "t-LinkSuccess": count === 1 })}>
        {count === 1 ? t("ENTFERNEN") : t("HINZUFÜGEN")}
      </Button>
      :
      <Button href={downloadLink} title={"Download"} target="_blank" size="small"
              className="ProductCard-actionButton t-iconLink">
        DOWNLOAD
        <GetApp className={"u-leftMarginSm"}/>
      </Button>
  }

  return (
    <CardActions className="ProductCard-actions" style={{ borderTop: "1px solid rgba(0,0,0, 0.1)" }}>
          <span className="ProductCard-actionButton t-body1 c-light-secondary">
            {
              type === "Contact Sales" ? "" :  ((hasPrice || !downloadLink) ? price + " €" : "FREE")
            }
          </span>

      {
        actionButton()
      }

    </CardActions>
  )
}

CardAction.defaultProps = {
  count: 0,
}


const mapStateToProps = (state) => {
  return {
    showOrderFab: state.showOrderFab,
    lang: state.lang,
  }
}

const mapDispatchToProps = {
  updateAddonCount,
  setShowOrderFab,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CardAction))
