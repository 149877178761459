import PropTypes from "prop-types"
import React from "react"

import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"

import "./product-card.scss"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import { updateAddonCount } from "../../state/actions"
import CardAction from "../card-action"


const ProductCard = ({ index, count, cardMedia, title, type, subtitle, description, bottomInfo, downloadLink, price, pdf, showOnlyPdf, isTraining, style, isArticle, articlePath, isAddonGrid }) => {

  const handleArticleClick = (event, path) => {
    navigate(path)
  }

  return (
    <Card elevation={0} className="ProductCard u-elevation-1 c-light-primary" style={style}>
      <CardActionArea target={"_blank"} href={pdf && (pdf.imageFile ? pdf.imageFile.publicURL : pdf.mediaItemUrl)}
                      onClick={isArticle ? event => handleArticleClick(event, articlePath) : null}>
        {cardMedia}

        <CardContent className="u-applyListStyle">
          <h2 className="t-h4" dangerouslySetInnerHTML={{ __html: title }}/>
          <p className="t-subtitle1 c-accent-dark">
            {subtitle}
          </p>
          <div className="ProductCard-body t-body3"
               dangerouslySetInnerHTML={{ __html: description }}>
          </div>

          <p className="ProductCard-body t-body3 c-light-secondary">
            {bottomInfo}
          </p>
        </CardContent>
      </CardActionArea>
      <CardAction isTraining={isTraining} isArticle={isArticle} count={count} index={index} isAddonGrid={isAddonGrid}
                  title={title} price={price} articlePath={articlePath} downloadLink={downloadLink}
                  showOnlyPdf={showOnlyPdf} pdf={pdf} type={type}/>

    </Card>
  )
}

ProductCard.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  bottomInfo: PropTypes.string,
  price: PropTypes.number,
  ctaFunction: PropTypes.func,
}

ProductCard.defaultProps = {
  showOnlyPdf: false,
}


const mapStateToProps = (state) => {
  return {
    addonOrders: state.addonOrders,
  }
}

const mapDispatchToProps = {
  updateAddonCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)
