import PropTypes from "prop-types"
import React from "react"

import "./section-outer.scss"
import classNames from "classnames"

const SectionOuter = ({ children, className, ...props }) => {

  return (
    <div className={classNames("SectionOuter", className)} {...props}>
      {children}
    </div>
  )
}

SectionOuter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default SectionOuter
